import React, { memo, useMemo, useCallback,Suspense, lazy } from 'react';
import Container from '@mui/material/Container';
import { Box, Typography, Link, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../Media/General/wiseterps-logo.png';
import { useNavigate } from 'react-router-dom';

const FacebookIcon = lazy(() => import('@mui/icons-material/Facebook'));
const InstagramIcon = lazy(() => import('@mui/icons-material/Facebook'));
const LinkedInIcon = lazy(() => import('@mui/icons-material/LinkedIn'));
const XIcon = lazy(() => import('@mui/icons-material/X'));


const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a1a1a',
  color: '#fff',
  padding: theme.spacing(6, 0),
}));

const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SocialLink = styled(Link)(({ theme }) => ({
  color: '#fff',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Footer = () => {
  const navigate = useNavigate();
  const handleLearnMoreClick = useCallback(() => {
    navigate('/?section=engage');
  }, [navigate]);

  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <FooterContent>
          <img src={logo} alt="WiseTerps Logo" style={{ width: '120px' }} />
          <FooterGrid container spacing={2} justifyContent="center" sx={{ mb: 2 }}>
            <Grid item>
              <FooterLink href="/privacy-policy" target="_blank" rel="noopener">
                Privacy Policy
              </FooterLink>
            </Grid>
            <Grid item>
              <FooterLink href="/terms-of-service" target="_blank" rel="noopener">
                Terms of Service
              </FooterLink>
            </Grid>
            <Grid item>
              <FooterLink href="/cookies-policy" target="_blank" rel="noopener">
                Cookies Policy
              </FooterLink>
            </Grid>
            <Grid item>
              <FooterLink href="/disclaimers" target="_blank" rel="noopener">
                Disclaimers
              </FooterLink>
            </Grid>
            <Grid item>
              <FooterLink href="/support" target="_blank" rel="noopener">
                Support/Contact
              </FooterLink>
            </Grid>
            <Grid item>
              <FooterLink onClick={handleLearnMoreClick} sx={{ cursor: 'pointer' }}>
                Learn More
              </FooterLink>
            </Grid>
          </FooterGrid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <SocialLink href="https://www.facebook.com/people/WiseTerps/61559187361144/?mibextid=LQQJ4d" target="_blank" rel="noopener">
              <IconButton sx={{ color: '#34d8eb' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <FacebookIcon />
                </Suspense>
              </IconButton>
            </SocialLink>
            <SocialLink href="https://twitter.com/wiseterps" target="_blank" rel="noopener">
              <IconButton sx={{ color: '#34eb98' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <XIcon />
                </Suspense>
              </IconButton>
            </SocialLink>
            <SocialLink href="https://instagram.com/wiseterps" target="_blank" rel="noopener">
              <IconButton sx={{ color: '#7d34eb' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <InstagramIcon />
                </Suspense>
              </IconButton>
            </SocialLink>
            <SocialLink href="https://linkedin.com/company/wiseterps" target="_blank" rel="noopener">
              <IconButton sx={{ color: '#3d34eb' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <LinkedInIcon />
                </Suspense>
              </IconButton>
            </SocialLink>
          </Box>
          <FooterText>Developed by GreenWave Software LLC</FooterText>
          <FooterText variant="body2">&copy; {year} WiseTerps. All rights reserved.</FooterText>
        </FooterContent>
      </Container>
    </FooterContainer>
  );
};

export default memo(Footer);
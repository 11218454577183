import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import Home from './General/Home';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AuthProvider from './Providers/AuthProvider';
import PageNotFound from './General/PageNotFound';
import LoadingPage from './General/LoadingPage';
import AgeVerificationWrapper from './General/AgeVerificationWrapper';
// Lazy load other components
const TerpLab = lazy(() => import('./Terp_Lab/TerpLab'));
const TerpenePage = lazy(() => import('./Terp_Lab/TerpenePage'));
const CommunityPage = lazy(() => import('./Community/CommunityPage'));
const ResearchPage = lazy(() => import('./Research/ResearchPage'));
const RecipiesPage = lazy(() => import('./Recipes/RecipesPage'));
const SignUpPage = lazy(() => import('./General/SignUpPage'));
const LoginPage = lazy(() => import('./General/LoginPage'));
const StrainPage = lazy(() => import('./Research/StrainPage'));
const TerpeneResearchPost = lazy(() => import('./Research/TerpeneResearchPost'));
const CreateStrainPage = lazy(() => import('./Research/CreateStrainPage'));
const FullCommunityView = lazy(() => import('./Community/FullCommunityView'));
const StrainResearchPost = lazy(() => import('./Research/StrainResearchPost'));
const PeerReviewResearchPost = lazy(() => import('./Research/PeerReviewResearchPost'));
const PublicMediaResearchPost = lazy(() => import('./Research/PublicMediaResearchPost'));
const THCPage = lazy(() => import('./Research/THCPage'));
const CBDPage = lazy(() => import('./Research/CBDPage'));
const CBGPage = lazy(() => import('./Research/CBGPage'));
const CBCPage = lazy(() => import('./Research/CBCPage'));
const CBNPage = lazy(() => import('./Research/CBNPage'));
const THCVPage = lazy(() => import('./Research/THCVPage'));
const PublicRecipeView = lazy(() => import('./Recipes/PublicRecipeView'));
const SettingsPage = lazy(() => import('./General/SettingsPage'));
const NofificationPage = lazy(() => import('./General/NotificationPage'));
const FreeSignUpPage = lazy(() => import('./General/FreeSignUp'));
const PaidSignUpPage = lazy(() => import('./General/PaidSignUp'));
const WelcomePage = lazy(() => import('./General/WelcomePage'));
const PrivacyPolicyPage = lazy(() => import('./General/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('./General/TermsAndConditionsPage'));
const CookiesPolicyPage = lazy(() => import('./General/CookiesPolicyPage'));
const DisclaiemrsPage = lazy(() => import('./General/DisclaimersPage'));
const SupportPage = lazy(() => import('./General/SupportPage'));
const ChooseAStrainPage = lazy(() => import('./Research/ChooseAStrainPage'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  palette: {
    primary: {
      main: '#81c784',
    },
    secondary: {
      main: '#b2dfdb',
    },
  },
});


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDb4SG6mzn8fXfCzFccr5qcixEa46d-M8U",
  authDomain: "wiseterps-39d4b.firebaseapp.com",
  projectId: "wiseterps-39d4b",
  storageBucket: "wiseterps-39d4b.appspot.com",
  messagingSenderId: "118773551954",
  appId: "1:118773551954:web:0e9ad303c36d09e181608b",
  measurementId: "G-FGKXHCK9TE"
};

initializeApp(firebaseConfig);

root.render(
  <React.StrictMode>
    <AgeVerificationWrapper>
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<div><LoadingPage/></div>}>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/Terp_lab" element={<TerpLab />} />
              <Route path="/Community" element={<CommunityPage />} />
              <Route path="/Community/:id" element={<FullCommunityView />} />
              <Route path="/Research" element={<ResearchPage />} />
              <Route path="/Recipes" element={<RecipiesPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/welcome-to-wiseterps" element={<WelcomePage />} />
              <Route path="/terpene/:terpene_name" element={<TerpenePage />} />
              <Route path="/strain/:strain_name" element={<StrainPage />} />
              <Route path="/strains/create_strain" element={<CreateStrainPage />} />
              <Route path="/research/terpene-research/:id" element={<TerpeneResearchPost />} />
              <Route path="/research/strain-research/:id" element={<StrainResearchPost />} />
              <Route path="/research/public-media-research/:id" element={<PublicMediaResearchPost />} />
              <Route path="/research/peer-review-research/:id" element={<PeerReviewResearchPost />} />
              <Route path="/research/THC" element={<THCPage />} />
              <Route path="/research/CBD" element={<CBDPage />} />
              <Route path="/research/CBG" element={<CBGPage />} />
              <Route path="/research/CBC" element={<CBCPage />} />
              <Route path="/research/CBN" element={<CBNPage />} />
              <Route path="/research/THCV" element={<THCVPage />} /> 
              <Route path="/Recipe/:id" element={<PublicRecipeView />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/notifications" element={<NofificationPage />} />
              <Route path="/sign-up-basic" element={<FreeSignUpPage/>} />
              <Route path="/sign-up-stoned" element={<PaidSignUpPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
              <Route path="/cookies-policy" element={<CookiesPolicyPage/>} />
              <Route path="/terms-of-service" element={<TermsAndConditionsPage/>} />
              <Route path="/disclaimers" element={<DisclaiemrsPage/>} />
              <Route path="/support" element={<SupportPage/>} />
              <Route path="/select-a-strain" element={<ChooseAStrainPage/>} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </Router>
    </AuthProvider>
    </AgeVerificationWrapper>
  </React.StrictMode>
);

reportWebVitals();
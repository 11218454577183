import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged, User, signOut } from 'firebase/auth';
import LoadingPage from '../General/LoadingPage';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_SERVER_ADDRESS;

interface AuthContextValue {
  currentUser: User | null;
  isSubscribed: boolean | null;
  loginSuccess: boolean;
  profileCheck: boolean | null;
}

export const AuthContext = React.createContext<AuthContextValue>({
  currentUser: null,
  isSubscribed: null,
  loginSuccess: false,
  profileCheck: null,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [profileCheck, setProfileCheck] = useState<boolean | null>(null);

  const createProfile = useCallback(async (user: User, retryCount = 0): Promise<boolean> => {
    console.log(`Attempting to create profile for user: ${user.uid}`);
    try {
      const idToken = await user.getIdToken(true);
      const csrfToken = Cookies.get('csrftoken');
      const response = await axios.post(
        `${API_BASE_URL}/api/create-profile/`,
        null,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );
      setLoginSuccess(true);
      return !response.data.profile_exists;
    } catch (error) {
      console.error(`Error creating profile for user ${user.uid}:`, error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      if (retryCount < 3) {
        await new Promise(resolve => setTimeout(resolve, 1000 * (retryCount + 1)));
        return createProfile(user, retryCount + 1);
      } else {
        console.error(`Failed to create profile for user ${user.uid} after multiple attempts`);
        setLoginSuccess(false);
        return false;
      }
    }
  }, []);

  const checkSubscription = useCallback(async (user: User): Promise<void> => {
    try {
      const csrfToken = Cookies.get('csrftoken');
      const idToken = await user.getIdToken(true);
      const response = await axios.get(`${API_BASE_URL}/subscription-api/check-subscription/`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });
      setIsSubscribed(response.data.is_active);
    } catch (error) {
      console.error('Error checking subscription:', error);
      setIsSubscribed(null);
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    let unsubscribe: () => void;

    const initializeAuth = async () => {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            // Ensure we have a valid token before proceeding
            await user.getIdToken(true);
            setCurrentUser(user);
            const profileCreated = await createProfile(user);
            setProfileCheck(profileCreated);
            if (profileCheck == null) {
              console.warn(`Profile not created for user: ${user.uid}`);
              // Consider implementing a retry mechanism or user notification here
              try {
                await createProfile(user);
              }
              catch (error) {
                console.error('Error during authentication process:', error);
              await signOut(auth);
              setCurrentUser(null);
              setIsSubscribed(null);
              setLoginSuccess(false);
              }
            }

            await checkSubscription(user);
          } catch (error) {
            console.error('Error during authentication process:', error);
            await signOut(auth);
            setCurrentUser(null);
            setIsSubscribed(null);
            setLoginSuccess(false);
          }
        } else {
          console.log('No user authenticated');
          setCurrentUser(null);
          setIsSubscribed(null);
          setLoginSuccess(false);
        }
        setLoading(false);
      });
    };

    initializeAuth();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [createProfile, checkSubscription]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider value={{ currentUser, isSubscribed, loginSuccess, profileCheck }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;